<template>
    <b-form ref="form" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
        <div class="row">
            <div class="col-6">
                <validated-input label="Social Media*" v-model="model.name"
                                 :rules="{required: true, max:20}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-vue-select @blur="resetModel" :options="typeOptions" label="Social Media Type*"
                                      v-model="model.site_map_type"
                                      :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6" v-if="model.site_map_type === 'Call us'">
                <validated-input label="Phone Number*" type="number"
                                 v-model="model.phone_number" :rules="{required: true, min:10, max:10}"
                                 :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input label="Website Link*" v-model="model.website_link"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                     loading-text="Saving..." :disabled="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'SocialMedia',

    data () {
        return {
            addUrl      : urls.cms.socialMedia.addEdit,
            typeOptions : [
                { label : 'Call us', value : 'Call us' },
                { label : 'Whatsapp group', value : 'Whatsapp group' },
                { label : 'Facebook', value : 'Facebook' },
                { label : 'Instagram', value : 'Instagram' },
                { label : 'Twitter', value : 'Twitter' },
                { label : 'WhatsApp', value : 'WhatsApp' },
                { label : 'LinkedIn', value : 'LinkedIn' },
                { label : 'Youtube', value : 'Youtube' },
                { label : 'WeChat', value : 'WeChat' },
                { label : 'Sina Weibo', value : 'Sina Weibo' },
                { label : 'QQ', value : 'QQ' },
                { label : 'Telegram', value : 'Telegram' },
                { label : 'Pinterest', value : 'Pinterest' },
                { label : 'SnapChat', value : 'SnapChat' },
                { label : 'Reddit', value : 'Reddit' },
                { label : 'Quora', value : 'Quora' },
                { label : 'Skype', value : 'Skype' },
                { label : 'Microsoft Teams', value : 'Microsoft Teams' },
                { label : 'Google Plus', value : 'Google Plus' }
            ]
        };
    },

    methods : {
        resetModel () {
            if (this.$refs.form.model.site_map_type !== 'Call us') {
                this.$refs.form.model.phone_number = '';
            }
        },
        formSuccess () {
            this.$notify('Successfully Added Social Media..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
